﻿
.slideup-wrapper {
  position: relative;

  > .slideup-menu {
    display: none;
    position: absolute;
    right: 0;
    bottom: $navbar-bottom-height;
    width: 100%;
    text-align: left;
    line-height: 1;
    margin: 0;
    z-index: -1;
    box-shadow: -5px -4px 20px -2px rgba(41, 41, 41, .08);
    will-change: margin;
    @include media-breakpoint-down('sm') {
      position: fixed;
    }

    &.open {
      display: block;
    }

    .nav-item {
      &.slideup-header {
        padding: .625rem 1rem;
        text-transform: uppercase;
      }

      > .nav-link {
        position: relative;
        display: block;
        padding: .75rem 1rem .75rem 2.5rem;
        font-size: .875rem;
        color: $black;
        white-space: normal;
        line-height: 1.2;

        &.share-copy-link {
          padding: .375rem 1rem;
          display: flex;
        }

        &:hover, &:focus {
          background: darken($white, 5%);
        }

        > .icon {
          position: absolute;
          left: 1rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.125rem;
          margin-right: .625rem;
        }

        .share-url {
          background: $gray-300;
          border: 1px solid $gray-200;
          padding: .32rem;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
          flex: 1;
        }

        .share-copy {
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          font-size: $small-font-size;
          margin-left: .25rem;

          .material-icons, .material-icons-outlined {
            display: block;
            text-align: center;
            vertical-align: middle;
            font-size: 1.125rem;
          }
        }

      }
    }

  }
}

.slideup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 995;
  display: none;
}