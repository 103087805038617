.nav {
  &.nav-pills-outlined {
    border: $border-width solid $border-color;
    border-radius: $border-radius-sm;
    overflow: hidden;

    .nav-item {
      flex: 1;
      border-right: $border-width solid $border-color;

      .nav-link {
        border-radius: 0;
        color: $dark;
        font-size: $font-size-xs;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        i {
          font-size: 1.125rem;
          vertical-align: middle;
          margin: -.2rem 0 0 0;
        }

        &.active {
          background: transparent;
          color: $primary;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &.nav-circular-icons {
    .nav-link {
      background: none;
      border: none;
      padding: 0 0 0 1.25rem;
      color: $dark;
      font-size: $font-size-sm;

      i {
        height: 2.375rem;
        width: 2.375rem;
        border-radius: 50%;
        background: $gray-200;
        margin: 0 .312rem .187rem 0;
        font-size: 1.125rem;
      }

      &.active {
        i {
          background: $dark;
          color: $primary;
        }
      }
    }
  }

  &.nav-lg {
    .nav-link {
      padding: $nav-link-padding-y + .25rem $nav-link-padding-x + .5rem;
    }
  }
}