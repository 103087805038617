﻿/* Errors */
.error {
  color: $red;
}

/* File Image Preview */
.img-preview {
  text-align: center;
  padding: 1rem 0;

  .img-preview-content {
    position: relative;
    display: inline-block;

    .close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      background: $gray-200;
      border-radius: 50%;
      padding: 0;
      margin: 0;
      line-height: 1;
      width: 2rem;
      height: 2rem;

      span {
        display: block;
        margin-top: -.2rem;
      }
    }
  }

}