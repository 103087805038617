﻿/* Borders */
.border-dashed {
  border-style: dashed !important;
}

/* Icons */
i, span {
  &.material-icons, &.material-icons-outlined {
    font-size: 1.3em;
    vertical-align: sub;
  }

  @each $social, $color in $social-colors {
    &.fab[class*=#{$social}] {
      color: $color;
    }
  }
}

/* Flex */
.flex-basis-auto {
  flex-basis: auto !important;
}

/* Texts */
.text-normal {
  font-size: $font-size-base !important;
}

.text-large {
  font-size: $font-size-base * 1.75 !important;
}

.text-extra-large {
  font-size: $font-size-base * 2.5 !important;
}

/* Width */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .w#{$infix}-100 {
      width: 100% !important;
    }
    .w#{$infix}-wide {
      width: 17rem !important;
      max-width: 100%
    }
  }
}

/* Cursor */
.cursor-pointer {
  cursor: pointer !important;
}

.wrapper-500 {
  max-width: 32rem;
}

/* Max width */
.mw-none {
  max-width: none !important;
}