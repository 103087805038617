html, body {
  height: 100%
}

body {
  &.navbar-fixed {
    padding-top: $navbar-top-height !important;
  }

    main {
      height: 100%;
      &.container:after {
        content: '';
        display: block;
        padding-bottom: $navbar-bottom-height !important;
      }
    }
}