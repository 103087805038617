﻿.sidenav {
  position: fixed;
  width: $sidenav-width;
  left: 0;
  top: 0;
  list-style: none;
  padding: 0;
  margin: 0 0 0 (-$sidenav-width);
  height: 100%;
  background-color: $sidenav-bg-color;
  z-index: 1032;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.12),
  0 1px 5px 0 rgba(0, 0, 0, 0.2);

  &.scroll-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;

    .nav {
      flex: 1;
      flex-wrap: nowrap;
      overflow-y: auto;
    }
  }

  // Right Align
  &.right-aligned {
    right: 0;
    left: auto;
    margin: 0 (-$sidenav-width) 0 0;
  }

  &.open {
    margin: 0;
  }

  .img-thumbnail {
    font-size: 1.5rem;
    line-height: 6.25rem;
    padding: 0;
    width: 6.25rem;
    height: 6.25rem;
    text-align: center;

    a {
      display: block;
      height: 100%;
      font-weight: bold;
      color: $primary;
      text-decoration: none;
    }
  }

  .nav-link {
    color: $dark;
    cursor: pointer;

    &:hover, &:focus {
      background: darken($sidenav-bg-color, 5%);
    }
  }

  .custom-control {
    padding: 0;

    .custom-control-input {
      top: $nav-link-padding-y + ($line-height-base * .8rem  - $custom-control-indicator-size) / 2 + .02rem;
      left: $nav-link-padding-x;

      ~ .custom-control-label {
        line-height: $line-height-base * .8rem;
        padding-left: $nav-link-padding-x + $custom-control-gutter + $custom-control-indicator-size;
        cursor: pointer;

        &:before, &:after {
          top: $nav-link-padding-y + ($line-height-base * .8rem  - $custom-control-indicator-size) / 2 + .02rem;
          left: $nav-link-padding-x;
        }
      }
    }
  }

}

// Fixed Sidenav shown
.sidenav.sidenav-fixed {
  // Right Align
  &.right-aligned {
    right: 0;
    left: auto;
  }

  left: 0;
  transform: translateX(0);
  position: fixed;
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba($black, .5);
  z-index: 1031;
  display: none;
}

.sidenav-opening, .sidenav-open {
  overflow: hidden !important;
}
