﻿.cssload-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, .8);
  width: 100%;
  overflow: hidden;
  display: none;
  z-index: 10000;

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    height: 100%;
    color: $gray-600;
    font-weight: bold;

    .loading-icon {
      display: inline-block;
      height: 2em;
      line-height: 2em;
      position: relative;
      vertical-align: middle;
      width: 2.5em;
      color: $gray-600;
      text-align: center;
      animation: heartbeat 1s forwards;
      animation-iteration-count: infinite;
      margin-bottom: 1rem;

      > .icon {
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        &:before {
          opacity: 0;
          content: '';
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          -webkit-font-feature-settings: 'liga';
          -webkit-font-smoothing: antialiased;
          font-size: 2em;
          vertical-align: middle;
        }

        &.icon-seq1:before {
          animation: loadingIconSeq1 6s forwards;
          animation-iteration-count: infinite;
        }

        &.icon-seq2:before {
          animation: loadingIconSeq2 4s forwards;
          animation-iteration-count: infinite;
          animation-delay: 1s;
        }
      }
    }
  }
}

@keyframes loadingIconSeq1 {
  0% {
    opacity: 0;
    content: "\e54e";
  }
  16.666666666666666666666666666667% {
    opacity: 1;
    content: "\e54e";
  }
  33.333333333333333333333333333333% {
    opacity: 0;
    content: "\e54e";
  }
  33.34% {
    opacity: 0;
    content: "\e838";
  }
  50% {
    opacity: 1;
    content: "\e838";
  }
  66.666666666666666666666666666667% {
    opacity: 0;
    content: "\e838";
  }
  66.67% {
    opacity: 0;
    content: "\e405";
  }
  83.333333333333333333333333333333% {
    opacity: 1;
    content: "\e405";
  }
  100% {
    opacity: 0;
    content: "\e405";
  }
}

@keyframes loadingIconSeq2 {
  0% {
    opacity: 0;
    content: "\e8b1";
  }
  25% {
    opacity: 1;
    content: "\e8b1";
  }
  50% {
    opacity: 0;
    content: "\e8b1";
  }
  50.01% {
    opacity: 0;
    content: "\e88a";
  }
  75% {
    opacity: 1;
    content: "\e88a";
  }
  100% {
    opacity: 0;
    content: "\e88a";
  }
}

/* Promotion Progress */

.promotion-progress-wrapper {
  .progress {
    height: .4rem;
  }
}
