﻿.floating-msg {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  max-width: 30rem;
  background: rgba($dark, 0.6);
  color: $white;
  border-radius: 6.25rem;
  padding: 1rem 1.25rem;
  text-align: center;
  pointer-events: none;
  z-index: 99999999;
}