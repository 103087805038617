.navbar {
  //Left toggle button spacing
  .navbar-header {
    margin-left: -15px;
  }

  //Bottom menu
  &#menu-bottom {
    .navbar-nav {
      line-height: 1;

      .nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }

      .nav-text {
        line-height: 1;
        font-size: $font-size-xs;
      }
    }
  }
}
