﻿.btn {
  &.btn-primary {
    color: $white;
  }

  &.btn-wide {
    width: 15.625rem;
    max-width: 100%;
    @include media-breakpoint-down('sm') {
      width: 100%;
    }
  }
}

/* Social */
.btn {
  &.btn-social {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }

  &.btn-google {
    background: #e0492f;
    border: 1px solid #e0492f;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.btn-facebook {
    background: #3365cb;
    border: 1px solid #3365cb;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }


}