.card {
  &.promotion-card {
    max-width: 25rem;
    border-radius: $border-radius-xl;

    .card-img-top {
      position: relative;
      min-height: 9rem;
      @include border-top-radius(subtract($border-radius-xl, 1px));
      @include border-top-radius(subtract($border-radius-xl, 1px));

      .promotion-type {
        position: absolute;
        left: .3rem;
        top: .3rem;
      }

      .promotion-title-overlay {
        background: linear-gradient(180deg, rgba($black, 0) 50%, rgba($black, 0.3) 100%);
        line-height: 1;
        text-shadow: 0 0 .2rem rgba($black, .5);

        .promotion-description {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .promotion-backcard-overlay {
        display: none;
        overflow-y: auto;
        cursor: default;
      }
    }

    &.is-active {
      .card-img-top {
        cursor: pointer;
      }
    }

    .nav {
      font-size: $font-size-xs;
      padding: 0 .375rem;

      .nav-item {
        min-height: 2rem;
        line-height: 2rem;

        .nav-link {
          cursor: pointer;

          &.active {
            color: $black !important;
          }
        }

        .material-icons {
          font-size: $font-size-base;
          margin: 0 0 .1rem;
        }

        &.promotion-like {
          position: relative;
          min-width: 1.75rem;

          input[type=checkbox] {
            position: absolute;
            left: -100vw;

            + label {
              align-self: center;
              position: relative;
              font-size: 1em;
              filter: grayscale(1);
              user-select: none;
              cursor: pointer;
              margin: 0;

              &:before, &:after {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                content: '';
              }

              &:before {
                box-sizing: border-box;
                margin: -2.25rem;
                border: solid 2.25rem #e2264d;
                width: 4.5rem;
                height: 4.5rem;
                transform: scale(0);
              }

              &:after {
                margin: -0.1875rem;
                width: 0.375rem;
                height: 0.375rem;
                box-shadow: 0.32476rem -3rem 0 -0.1875rem #ff8080, -0.32476rem -2.625rem 0 -0.1875rem #ffed80, 2.54798rem -1.61656rem 0 -0.1875rem #ffed80, 1.84982rem -1.89057rem 0 -0.1875rem #a4ff80, 2.85252rem 0.98418rem 0 -0.1875rem #a4ff80, 2.63145rem 0.2675rem 0 -0.1875rem #80ffc8, 1.00905rem 2.84381rem 0 -0.1875rem #80ffc8, 1.43154rem 2.22414rem 0 -0.1875rem #80c8ff, -1.59425rem 2.562rem 0 -0.1875rem #80c8ff, -0.84635rem 2.50595rem 0 -0.1875rem #a480ff, -2.99705rem 0.35095rem 0 -0.1875rem #a480ff, -2.48692rem 0.90073rem 0 -0.1875rem #ff80ed, -2.14301rem -2.12438rem 0 -0.1875rem #ff80ed, -2.25479rem -1.38275rem 0 -0.1875rem #ff8080;
              }

              .material-icons {
                margin: 0 0 0 .1rem;

                &:after {
                  content: 'favorite_border';
                }
              }
            }

            &:checked + label {
              color: #e2264d;
              filter: none;
              will-change: font-size;
              animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);

              &:before, &:after {
                animation: inherit;
                animation-timing-function: ease-out;
              }

              &:before {
                will-change: transform, border-width, border-color;
                animation-name: bubble;
              }

              &:after {
                will-change: opacity, box-shadow;
                animation-name: sparkles;
              }

              .material-icons:after {
                content: 'favorite';
              }
            }
          }
        }
      }
    }

    .card-footer {
      .btn-activate, .not-available-description {
        border-radius: 0 0 $border-radius-xl $border-radius-xl;
      }
    }
  }
}