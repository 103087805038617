@keyframes heart {
  0%, 17.5% {
    font-size: 0;
  }
}

@keyframes bubble {
  15% {
    -webkit-transform: scale(1);
    transform: scale(1);
    border-color: $primary;
    border-width: 2.25rem;
  }
  30%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    border-color: $primary;
    border-width: 0;
  }
}

@keyframes sparkles {
  0%, 20% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    box-shadow: 0.32476rem -2.4375rem 0 0rem #ff8080, -0.32476rem -2.0625rem 0 0rem #ffed80, 2.1082rem -1.26585rem 0 0rem #ffed80, 1.41004rem -1.53985rem 0 0rem #a4ff80, 2.30412rem 0.85901rem 0 0rem #a4ff80, 2.08305rem 0.14233rem 0 0rem #80ffc8, 0.76499rem 2.33702rem 0 0rem #80ffc8, 1.18748rem 1.71734rem 0 0rem #80c8ff, -1.35019rem 2.0552rem 0 0rem #80c8ff, -0.60229rem 1.99916rem 0 0rem #a480ff, -2.44865rem 0.22578rem 0 0rem #a480ff, -1.93852rem 0.77557rem 0 0rem #ff80ed, -1.70323rem -1.77366rem 0 0rem #ff80ed, -1.81501rem -1.03204rem 0 0rem #ff8080;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.75);
  }
}